import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import SEO from 'sections/Layout/SEO';

import {
  main,
  successImg,
  link,
} from 'styles/pages/digitec/success.module.scss';
import 'styles/pages/digitec/general.scss';

const seo = {
  title: 'digitec.ch Survey | Promo | Screver Feedback Management Solution',
  metaDesc:
    'Look at the digitec.ch website with our Screver Feedback Survey. It allows you to attract, collect, analyze in real-time, and implement data-driven actions.',
  opengraphUrl: 'https://screver.com/cases/cx/digitec/success/',
};

const success = () => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <SEO seo={seo} themeColor="#ffffff" />

      <div className={classnames('scr-digitect-wrap')}>
        <main className={main}>
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-cart-header.png"
            alt=""
          />

          <Link to="/cases/cx/digitec/email-1" className={link}>
            <img
              src="https://wp.screver.com/wp-content/uploads/2023/08/Group-7.png"
              alt=""
              className={successImg}
            />
          </Link>

          <iframe
            src="https://go.screver.com/screver-playground/digitec-purchase-feedback"
            title="Survey"
          ></iframe>

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-footer.png"
            alt=""
          />
        </main>
      </div>
    </>
  );
};

export default success;
